import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import LogoRed from "../img/logo-red.svg"

const Navbar = class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			active: false,
			navBarActiveClass: "",
		}
	}

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: "is-active",
					  })
					: this.setState({
							navBarActiveClass: "",
					  })
			}
		)
	}

	render() {
		return (
			<nav
				className="navbar"
				role="navigation"
				aria-label="main-navigation"
			>
				<div className="container">
					<div className="navbar-brand">
						{/* Hamburger menu */}
						<button
							className={`navbar-burger burger ${this.state.navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</button>
						<Link to="/" className="navbar-item site-title" title="Logo">
							<LogoRed className="navbar-logo-img" />
							<div>
								<div className="site-title-main title-font">
									{this.props.data.titleMain}
								</div>
								<div className="site-title-sub">{this.props.data.titleSub}</div>
							</div>
						</Link>
					</div>
					<div
						id="navMenu"
						className={`navbar-menu ${this.state.navBarActiveClass}`}
					>
						<div className="navbar-start has-text-centered">
							{this.props.data.nodes.map(({ url, display }) => {
								return (
									<Link
										activeClassName="active"
										className="navbar-item"
										to={url}
										key={url}
									>
										{display}
									</Link>
								)
							})}
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

// export default Navbar

export default () => (
	<StaticQuery
		query={graphql`
			query Navbar {
				stringsYaml {
					titleMain
					titleSub
				}
				allMenuYaml {
					nodes {
						url
						display
					}
				}
			}
		`}
		render={({ stringsYaml, allMenuYaml }) => (
			<Navbar data={{ ...stringsYaml, ...allMenuYaml }} />
		)}
	/>
)
