import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"

class Footer extends React.Component {
	render() {
		const { strings, contactinfo, afflictions, menuitems } = this.props.data

		return (
			<footer className="footer">
				<div className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-4">
								<section>
									<p>
										<strong>{strings.titleMain}</strong>
										<br />
										<span>{strings.titleSub}</span>
									</p>
									<p>
										{contactinfo.street}
										<br />
										{contactinfo.zipcode} {contactinfo.city}
									</p>
									<p>{contactinfo.phone}</p>
									<p>{contactinfo.email}</p>
								</section>
							</div>
							<div className="column is-2">
								<section className="menu">
									<strong>Links</strong>
									<ul className="menu-list">
										{menuitems.map((menuitem) => {
											if (!menuitem) return <></>
											return (
												<li key={menuitem.url}>
													<Link className="navbar-item" to={menuitem.url}>
														{menuitem.display}
													</Link>
												</li>
											)
										})}
									</ul>
								</section>
							</div>
							<div className="column is-4">
								<section className="menu">
									<strong>Beschwerden</strong>
									<ul className="menu-list">
										{afflictions.map(({ node }) => {
											if (!node) return <></>
											const slug = node.fields.slug
											const title = node.frontmatter.title
											return (
												<li key={slug}>
													<Link className="navbar-item" to={slug}>
														{title}
													</Link>
												</li>
											)
										})}
									</ul>
								</section>
							</div>
							<div className="column is-2">
								<section>
									<strong>Rechtliches</strong>
									<ul className="menu-list">
										<li>
											<Link className="navbar-item" to="/impressum">
												Impressum
											</Link>
										</li>
										<li>
											<Link className="navbar-item" to="/datenschutz">
												Datenschutz
											</Link>
										</li>
									</ul>
								</section>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

Footer.propTypes = {
	data: PropTypes.shape({}),
}

export default () => (
	<StaticQuery
		query={graphql`
			query FooterQuery {
				stringsYaml {
					googlemapsAPIkey
					siteName
					titleMain
					titleSub
				}
				contactinfoYaml {
					street
					zipcode
					city
					email
					location
					phone
				}
				allMenuYaml {
					nodes {
						url
						display
					}
				}
				allMarkdownRemark(
					filter: { fields: { slug: { glob: "/beschwerden/*" } } }
				) {
					edges {
						node {
							frontmatter {
								title
							}
							fields {
								slug
							}
						}
					}
				}
			}
		`}
		render={({
			stringsYaml,
			contactinfoYaml,
			allMenuYaml,
			allMarkdownRemark,
		}) => (
			<Footer
				data={{
					strings: stringsYaml,
					menuitems: allMenuYaml.nodes,
					contactinfo: contactinfoYaml,
					afflictions: allMarkdownRemark.edges,
				}}
			/>
		)}
	/>
)
